
































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {
  ActivityDto,
  CmsAnchorDto,
  CmsAnchorDtoPagedResultDto,
  QuestionType,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "AnchorList",
  components: {
    PagedTableView,
    ExportButton,
  },
})
export default class AnchorList extends Vue {
  queryForm = {
    title: "",
    host: "",
    lecturer: "",
    trainTypeId: undefined,
    startDate: undefined,
    endDate: undefined,
  };
  trainTypeList: any = [];

  sexList = [
    { name: "男", value: "男" },
    { name: "女", value: "女" },
  ];

  created() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "TrainType",
        maxResultCount: 1000,
      })
      .then((res: any) => {
        this.trainTypeList = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.trainManagement.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "trainManagementTableCreate",
    });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "trainManagementTableEdit",
      params: { id: row.id! + "" },
    });
  }

  // 详情
  handleDetail(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "trainManagementTableDetail",
      params: { id: row.id! + "" },
    });
  }

  // 删除
  async handleDelete(index: number, row: any) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.trainManagement
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
